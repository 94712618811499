import React, { useState, useEffect } from 'react';
import { db } from '../../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';

function RejectedEmails() {
    const [ emails, setEmails ] = useState( [] );

    const fetchEmails = async () => {
        const querySnapshot = await getDocs( collection( db, 'emails' ) );
        const emailsList = querySnapshot.docs.map( ( doc ) => ( { id: doc.id, ...doc.data() } ) );

        setEmails( emailsList );
    };

    useEffect( () => {
        fetchEmails();
    }, [] );

    return (
        <div className="admin-accounts">
            <h1>Emails that opted out</h1>
            <div className="table-wrapper">
                <table>
                    <tbody>
                        { emails.map( ( email ) => (
                            <tr key={ email.id }>
                                <td>{ email.email }</td>
                            </tr>
                        ) ) }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default RejectedEmails;
