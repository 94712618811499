import React, { useState, useEffect } from 'react';
import { db, storage } from '../../firebaseConfig';
import { collection, doc, setDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function CaseStudyForm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [ title, setTitle ] = useState( '' );
    const [ content, setContent ] = useState( '' );
    const [ image, setImage ] = useState( null );
    const [ imageUrl, setImageUrl ] = useState( '' );
    const [ loading, setLoading ] = useState( false );
    const [ error, setError ] = useState( '' );
    const [ success, setSuccess ] = useState( '' );

    useEffect( () => {
        const fetchCaseStudy = async () => {
            if ( id ) {
                try {
                    const docRef = doc( db, 'caseStudies', id );
                    const docSnap = await getDoc( docRef );
                    if ( docSnap.exists() ) {
                        const data = docSnap.data();
                        setTitle( data.title );
                        setContent( data.content );
                        setImageUrl( data.imageUrl );
                    } else {
                        console.error( 'No such document!' );
                    }
                } catch ( err ) {
                    console.error( 'Error fetching case study:', err );
                    setError( 'Failed to fetch case study.' );
                }
            }
        };
        fetchCaseStudy();
    }, [ id ] );

    const handleImageUpload = async () => {
        if ( !image ) return '';

        const storageRef = ref( storage, `caseStudies/${ Date.now() }_${ image.name }` );
        await uploadBytes( storageRef, image );
        const url = await getDownloadURL( storageRef );
        return url;
    };

    const handleSubmit = async ( e ) => {
        e.preventDefault();
        setLoading( true );
        setError( '' );
        setSuccess( '' );

        try {
            const imageUrl = await handleImageUpload();
            const caseStudyData = {
                title,
                content,
                imageUrl: imageUrl || imageUrl,
            };

            const docRef = id ? doc( db, 'caseStudies', id ) : doc( collection( db, 'caseStudies' ) );
            await setDoc( docRef, caseStudyData );

            setSuccess( 'Case study saved successfully.' );
            navigate( '/d3Ya8m3Fp6/case-studies' );
        } catch ( err ) {
            console.error( 'Error saving case study:', err );
            setError( 'Failed to save case study.' );
        } finally {
            setLoading( false );
        }
    };

    return (
        <div className="case-study-form">
            <h1>{ id ? 'Edit Case Study' : 'Create New Case Study' }</h1>
            { error && <p className="error">{ error }</p> }
            { success && <p className="success">{ success }</p> }
            <form onSubmit={ handleSubmit }>
                <div>
                    <label>Title:</label>
                    <input
                        type="text"
                        value={ title }
                        onChange={ ( e ) => setTitle( e.target.value ) }
                        required
                    />
                </div>
                <div>
                    <label>Content:</label>
                    <ReactQuill
                        theme="snow"
                        value={ content }
                        onChange={ setContent }
                        modules={ {
                            toolbar: [
                                [ 'bold', 'italic', 'underline', 'strike' ],
                                [ { list: 'ordered' }, { list: 'bullet' } ],
                                [ 'link', 'image', 'video' ],
                                [ 'clean' ]
                            ],
                        } }
                    />
                </div>
                <div>
                    <label>Image:</label>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={ ( e ) => setImage( e.target.files[ 0 ] ) }
                    />
                </div>
                { imageUrl && <img src={ imageUrl } alt="Case Study" style={ { width: '100px', marginTop: '10px' } } /> }
                <button type="submit" disabled={ loading }>{ loading ? 'Saving...' : ( id ? 'Update Case Study' : 'Add Case Study' ) }</button>
            </form>
        </div>
    );
}

export default CaseStudyForm;
