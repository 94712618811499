import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { Helmet } from 'react-helmet';

function BlogList() {
    const [ blogPosts, setBlogPosts ] = useState( [] );

    useEffect( () => {
        const fetchBlogPosts = async () => {
            const querySnapshot = await getDocs( collection( db, 'blogPosts' ) );
            const posts = querySnapshot.docs.map( doc => ( {
                id: doc.id,
                ...doc.data(),
            } ) );
            setBlogPosts( posts );
        };

        fetchBlogPosts();
    }, [] );

    return (
        <div className="blog-page">
            <Helmet>
                <title>Blog - SafeMobility Solutions Ltd</title>
                <meta name="description" content="Welcome to our blog, your go-to resource for the latest insights and innovations in accessibility and stair climbing solutions." />
            </Helmet>
            <h1 className="blog-heading">Blog</h1>
            <p className="blog-text"><strong>Insights and Innovations in Mobility Solutions</strong></p>
            <p className="blog-text">Welcome to our blog, your go-to resource for the latest insights and innovations in accessibility and stair climbing solutions. Our blog features expert articles, industry news, and practical tips to help you stay informed about the evolving landscape of mobility solutions. Whether you're a facilities manager, housing manager, event organiser, or homeowner, our blog offers valuable content to guide your decision-making and enhance accessibility in your environment.</p>
            <p className="blog-text">Explore our articles to discover how our state-of-the-art solutions can improve safety and accessibility. Learn more about the benefits of our services on our <Link to="/services">Services Page</Link> or reach out through our <Link to="/contact" className="green">Contact Us</Link> for personalised advice and support.</p>
            <div className="blog-list">
                { blogPosts.map( post => (
                    <div key={ post.id } className="blog-card">
                        <img src={ post.imageUrl } alt={ post.title } className="blog-image" />
                        <div className="blog-info">
                            <h2>{ post.title }</h2>
                            <p className="blog-date">Posted on { new Date( post.createdAt.seconds * 1000 ).toLocaleDateString( 'en-GB', { month: 'long', day: 'numeric', year: 'numeric' } ) }</p>
                            <p className="blog-description">{ post.content.replace( /<[^>]+>/g, '' ).substring( 0, 100 ) }...</p>
                            <Link to={ `/blog/${ post.id }` } className="read-more-link">Read more</Link>
                        </div>
                    </div>
                ) ) }
            </div>
        </div>
    );
}

export default BlogList;
