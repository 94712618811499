import React, { useState, useEffect } from 'react';

const ScrollToTop = () => {
    const [ isVisible, setIsVisible ] = useState( false );

    // Show button when page is scrolled upto given distance
    const toggleVisibility = () => {
        if ( window.pageYOffset > 100 ) {
            setIsVisible( true );
        } else {
            setIsVisible( false );
        }
    };

    // Set the top cordinate to 0
    // make scrolling smooth
    const scrollToTop = () => {
        window.scrollTo( {
            top: 0,
            behavior: "smooth"
        } );
    };

    useEffect( () => {
        window.addEventListener( "scroll", toggleVisibility );

        return () => {
            window.removeEventListener( "scroll", toggleVisibility );
        };
    }, [] );

    return (
        <div className="scroll-to-top">
            { isVisible &&
                // <button onClick={ scrollToTop } style={ { position: 'fixed', bottom: '20px', right: '400px', cursor: 'pointer' } }>
                //     <i class="fa-regular fa-circle-up"></i>
                // </button>
                <button onClick={ scrollToTop } style={ { position: 'fixed', bottom: '20px', right: '20px', cursor: 'pointer' } }>
                    <i class="fa-regular fa-circle-up"></i>
                </button>
            }
        </div>
    );
};

export default ScrollToTop;
