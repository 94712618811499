import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, storage } from '../../firebaseConfig';
import { collection, doc, setDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's CSS

function ServiceForm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [ title, setTitle ] = useState( '' );
    const [ description, setDescription ] = useState( '' );
    const [ image, setImage ] = useState( null );
    const [ imageUrl, setImageUrl ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const [ success, setSuccess ] = useState( '' );

    useEffect( () => {
        const fetchService = async () => {
            if ( id ) {
                try {
                    const docRef = doc( db, 'services', id );
                    const docSnap = await getDoc( docRef );
                    if ( docSnap.exists() ) {
                        const data = docSnap.data();
                        setTitle( data.title );
                        setDescription( data.description );
                        setImageUrl( data.imageUrl );
                    } else {
                        console.log( 'No such document!' );
                    }
                } catch ( err ) {
                    console.error( 'Error fetching service:', err );
                    setError( 'Failed to fetch service data.' );
                }
            }
        };

        fetchService();
    }, [ id ] );

    const handleImageUpload = async () => {
        if ( !image ) return '';

        const storageRef = ref( storage, `services/${ image.name }` );
        await uploadBytes( storageRef, image );
        const url = await getDownloadURL( storageRef );
        return url;
    };

    const handleSubmit = async ( e ) => {
        e.preventDefault();
        setError( '' );
        setSuccess( '' );

        try {
            const url = await handleImageUpload();
            const serviceData = {
                title,
                description,
                imageUrl: url || imageUrl,
            };

            const docRef = id ? doc( db, 'services', id ) : doc( collection( db, 'services' ) );
            await setDoc( docRef, serviceData );

            setSuccess( 'Service saved successfully.' );
            navigate( '/d3Ya8m3Fp6/services' ); // Redirect to service management page
        } catch ( err ) {
            console.error( 'Error saving service:', err );
            setError( 'Failed to save service.' );
        }
    };

    return (
        <div className="service-form">
            <h1>{ id ? 'Edit Service' : 'Create New Service' }</h1>
            { error && <p className="error">{ error }</p> }
            { success && <p className="success">{ success }</p> }

            <form onSubmit={ handleSubmit }>
                <div>
                    <label>Title:</label>
                    <input
                        type="text"
                        value={ title }
                        onChange={ ( e ) => setTitle( e.target.value ) }
                        required
                    />
                </div>
                <div>
                    <label>Description:</label>
                    <ReactQuill
                        theme="snow"
                        value={ description }
                        onChange={ setDescription }
                        modules={ {
                            toolbar: [
                                [ 'bold', 'italic', 'underline', 'strike' ],
                                [ { list: 'ordered' }, { list: 'bullet' } ],
                                [ 'link', 'image', 'video' ],
                                [ 'clean' ]
                            ]
                        } }
                    />
                </div>
                <div>
                    <label>Image:</label>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={ ( e ) => setImage( e.target.files[ 0 ] ) }
                    />
                </div>
                { imageUrl && <img src={ imageUrl } alt="Service" style={ { width: '100px', marginTop: '10px' } } /> }
                <button type="submit">Save Service</button>
            </form>
        </div>
    );
}

export default ServiceForm;
