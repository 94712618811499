import React from 'react';
import AdminNavbar from './components/d3Ya8m3Fp6/AdminNavbar';
import useScrollToTop from './hooks/useScrollToTop';

const AdminLayout = ( { children } ) => {
    useScrollToTop();

    return (
        <div>
            <AdminNavbar />
            <main>{ children }</main>
        </div>
    );
};

export default AdminLayout;
