import React from 'react';
import Navbar from './components/client/Navbar';
import Footer from './components/client/Footer';
import ScrollToTop from './components/client/ScrollToTop';
import useScrollToTop from './hooks/useScrollToTop';
// import Chatbot from './components/client/Chatbot';

const ClientLayout = ( { children } ) => {
    useScrollToTop();

    return (
        <div>
            <Navbar />
            <main>{ children }</main>
            {/* <Chatbot /> */}
            <ScrollToTop />
            <Footer />
        </div>
    );
};

export default ClientLayout;
