import React, { useState, useEffect } from 'react';
import { db } from '../../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Faq() {
    const [ faqs, setFaqs ] = useState( [] );
    const [ activeIndex, setActiveIndex ] = useState( null );

    useEffect( () => {
        const fetchFaqs = async () => {
            try {
                const querySnapshot = await getDocs( collection( db, 'faqs' ) );
                const faqList = querySnapshot.docs.map( ( doc ) => ( { id: doc.id, ...doc.data() } ) );
                setFaqs( faqList );
            } catch ( err ) {
                console.error( 'Error fetching FAQs:', err );
            }
        };

        fetchFaqs();
    }, [] );

    const toggleFaq = ( index ) => {
        setActiveIndex( activeIndex === index ? null : index );
    };

    return (
        <div className="faq-page">
            <Helmet>
                <title>FAQ - SafeMobility Solutions Ltd</title>
                <meta name="description" content="If you have any questions not listed here, please contact us." />
            </Helmet>
            <h1>Frequently Asked Questions</h1>
            <p>If you have any questions not listed here, please <Link to="/contact" className="green" style={ { fontWeight: 'bold', textDecoration: 'none' } }>contact us</Link>.</p>
            <div className="faq-list">
                { faqs.map( ( faq, index ) => (
                    <div key={ faq.id } className="faq-item">
                        <div className="faq-question" onClick={ () => toggleFaq( index ) }>
                            <strong>Q: { faq.question }</strong>
                            <span className="toggle-icon">{ activeIndex === index ? '-' : '+' }</span>
                        </div>
                        { activeIndex === index && (
                            <div className="faq-answer">
                                <p>A: { faq.answer }</p>
                            </div>
                        ) }
                    </div>
                ) ) }
            </div>
        </div>
    );
}

export default Faq;
