import React, { useState, useEffect } from 'react';
import { db, storage } from '../../firebaseConfig';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';
import { Link } from 'react-router-dom';

function TestimonialManager() {
    const [ testimonials, setTestimonials ] = useState( [] );
    const [ error, setError ] = useState( '' );

    useEffect( () => {
        const fetchTestimonials = async () => {
            const querySnapshot = await getDocs( collection( db, 'testimonials' ) );
            setTestimonials( querySnapshot.docs.map( doc => ( { id: doc.id, ...doc.data() } ) ) );
        };
        fetchTestimonials();
    }, [] );

    const handleDeleteTestimonial = async ( testimonial ) => {
        try {
            // Delete the image from Firebase Storage
            if ( testimonial.imageUrl ) {
                const imageRef = ref( storage, testimonial.imageUrl );
                await deleteObject( imageRef );
            }
            // Delete the testimonial document from Firestore
            await deleteDoc( doc( db, 'testimonials', testimonial.id ) );
            setTestimonials( testimonials.filter( t => t.id !== testimonial.id ) );
        } catch ( err ) {
            setError( 'Failed to delete testimonial. ' + err.message );
        }
    };

    return (
        <div className="testimonial-manager">
            <h1>Manage Testimonials</h1>
            <Link to="/d3Ya8m3Fp6/testimonials/new" className="button-new">Add New Testimonial</Link>
            { error && <div className="error">{ error }</div> }
            <div className="testimonial-list">
                { testimonials.map( ( testimonial ) => (
                    <div key={ testimonial.id } className="testimonial-item">
                        <p>{ testimonial.customerName }</p>
                        <Link to={ `/d3Ya8m3Fp6/testimonials/edit/${ testimonial.id }` }>Edit</Link>
                        <button onClick={ () => handleDeleteTestimonial( testimonial ) }>Delete</button>
                    </div>
                ) ) }
            </div>
        </div>
    );
}

export default TestimonialManager;
