import React, { useState, useEffect } from 'react';
import { db, storage } from '../../firebaseConfig';
import { collection, addDoc, doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useNavigate, useParams } from 'react-router-dom';

function AddTestimonial() {
    const { id } = useParams(); // Get the testimonial ID from the route params
    const [ customerName, setCustomerName ] = useState( '' );
    const [ review, setReview ] = useState( '' );
    const [ serviceProvided, setServiceProvided ] = useState( '' );
    const [ image, setImage ] = useState( null );
    const [ imageUrl, setImageUrl ] = useState( '' ); // Existing image URL
    const [ loading, setLoading ] = useState( false );
    const navigate = useNavigate();

    useEffect( () => {
        // If there's an ID, fetch the existing testimonial data
        const fetchTestimonial = async () => {
            if ( id ) {
                const docRef = doc( db, 'testimonials', id );
                const docSnap = await getDoc( docRef );
                if ( docSnap.exists() ) {
                    const data = docSnap.data();
                    setCustomerName( data.customerName );
                    setReview( data.review );
                    setServiceProvided( data.serviceProvided );
                    setImageUrl( data.imageUrl ); // Set existing image URL
                } else {
                    console.error( 'No such document!' );
                }
            }
        };
        fetchTestimonial();
    }, [ id ] );

    const handleSubmit = async ( e ) => {
        e.preventDefault();
        setLoading( true );

        let newImageUrl = imageUrl; // Default to existing image URL

        // If a new image is uploaded, upload it to Firebase Storage
        if ( image ) {
            const imageRef = ref( storage, `testimonials/${ Date.now() }_${ image.name }` );
            const uploadTask = uploadBytesResumable( imageRef, image );

            await new Promise( ( resolve, reject ) => {
                uploadTask.on(
                    "state_changed",
                    ( snapshot ) => {
                        const progress = ( snapshot.bytesTransferred / snapshot.totalBytes ) * 100;
                        console.log( 'Upload is ' + progress + '% done' );
                    },
                    ( error ) => {
                        console.error( "Upload failed", error );
                        alert( "Image upload failed, please try again." );
                        reject( error );
                    },
                    async () => {
                        newImageUrl = await getDownloadURL( uploadTask.snapshot.ref );
                        resolve();
                    }
                );
            } );
        }

        try {
            if ( id ) {
                // Update existing testimonial
                const docRef = doc( db, 'testimonials', id );
                await updateDoc( docRef, {
                    customerName,
                    review,
                    serviceProvided,
                    imageUrl: newImageUrl
                } );
            } else {
                // Add new testimonial
                await addDoc( collection( db, 'testimonials' ), {
                    customerName,
                    review,
                    serviceProvided,
                    imageUrl: newImageUrl,
                    createdAt: serverTimestamp() // Add createdAt timestamp
                } );
            }

            navigate( '/d3Ya8m3Fp6/testimonials' );
        } catch ( error ) {
            console.error( 'Error saving testimonial:', error );
            alert( 'Failed to save testimonial. Please try again.' );
        } finally {
            setLoading( false );
        }
    };

    return (
        <form onSubmit={ handleSubmit } className="add-testimonial-form">
            <h1>{ id ? 'Edit Testimonial' : 'Add New Testimonial' }</h1>
            <label>
                Customer/Business Name:
                <input type="text" value={ customerName } onChange={ ( e ) => setCustomerName( e.target.value ) } required />
            </label>
            <label>
                Review:
                <textarea value={ review } onChange={ ( e ) => setReview( e.target.value ) } required />
            </label>
            <label>
                Service Provided:
                <input type="text" value={ serviceProvided } onChange={ ( e ) => setServiceProvided( e.target.value ) } required />
            </label>
            <label>
                Image:
                <input type="file" accept="image/*" onChange={ ( e ) => setImage( e.target.files[ 0 ] ) } />
            </label>
            { imageUrl && <img src={ imageUrl } alt="Testimonial" style={ { width: '100px', marginTop: '10px' } } /> }
            <button type="submit" disabled={ loading }>{ loading ? 'Saving...' : ( id ? 'Update Testimonial' : 'Add Testimonial' ) }</button>
        </form>
    );
}

export default AddTestimonial;
