import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebaseConfig';
import { updatePassword, signOut } from 'firebase/auth';

function Settings() {
    const [ currentPassword, setCurrentPassword ] = useState( '' );
    const [ newPassword, setNewPassword ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const [ success, setSuccess ] = useState( '' );
    const navigate = useNavigate();

    const handleChangePassword = async ( e ) => {
        e.preventDefault();
        try {
            const user = auth.currentUser;
            if ( user ) {
                // Update password
                await updatePassword( user, newPassword );
                setSuccess( 'Password updated successfully.' );
                setError( '' );
            } else {
                throw new Error( 'No user is signed in.' );
            }
        } catch ( error ) {
            setError( error.message );
            setSuccess( '' );
        }
    };

    const handleLogout = async () => {
        try {
            await signOut( auth );
            navigate( '/d3Ya8m3Fp6' );
        } catch ( error ) {
            setError( error.message );
        }
    };

    return (
        <div className="settings">
            <h1>Settings</h1>
            { error && <p className="error">{ error }</p> }
            { success && <p className="success">{ success }</p> }
            <form onSubmit={ handleChangePassword }>
                <div>
                    <label>Current Password:</label>
                    <input
                        type="password"
                        value={ currentPassword }
                        onChange={ ( e ) => setCurrentPassword( e.target.value ) }
                        required
                    />
                </div>
                <div>
                    <label>New Password:</label>
                    <input
                        type="password"
                        value={ newPassword }
                        onChange={ ( e ) => setNewPassword( e.target.value ) }
                        required
                    />
                </div>
                <button type="submit">Change Password</button>
            </form>
            <button onClick={ handleLogout } className="logout-button">Logout</button>
        </div>
    );
}

export default Settings;
