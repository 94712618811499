import React, { useState, useEffect } from 'react';
import { db, storage } from '../../firebaseConfig';
import { collection, getDocs, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import { Link } from 'react-router-dom';

function CaseStudyManager() {
    const [ caseStudies, setCaseStudies ] = useState( [] );
    const [ error, setError ] = useState( '' );
    const [ success, setSuccess ] = useState( '' );

    useEffect( () => {
        const fetchCaseStudies = async () => {
            try {
                const querySnapshot = await getDocs( collection( db, 'caseStudies' ) );
                const caseStudyList = querySnapshot.docs.map( doc => ( { id: doc.id, ...doc.data() } ) );
                setCaseStudies( caseStudyList );
            } catch ( err ) {
                console.error( 'Error fetching case studies:', err );
                setError( 'Failed to load case studies.' );
            }
        };

        fetchCaseStudies();
    }, [] );

    const handleDeleteCaseStudy = async ( id ) => {
        try {
            // First, get the case study to find the image URL
            const docRef = doc( db, 'caseStudies', id );
            const docSnap = await getDoc( docRef );

            if ( docSnap.exists() ) {
                // Delete the image from storage
                const imageUrl = docSnap.data().imageUrl;
                if ( imageUrl ) {
                    const imageRef = ref( storage, imageUrl );
                    await deleteObject( imageRef );
                }

                // Then delete the document
                await deleteDoc( docRef );
                setCaseStudies( caseStudies.filter( cs => cs.id !== id ) );
                setSuccess( 'Case study deleted successfully.' );
            } else {
                console.error( 'Document does not exist!' );
                setError( 'No such case study found.' );
            }
            setError( '' );
        } catch ( err ) {
            console.error( 'Error deleting case study:', err );
            setError( 'Failed to delete case study.' );
        }
    };

    return (
        <div className="case-study-manager">
            <h1>Case Studies</h1>
            { error && <p className="error">{ error }</p> }
            { success && <p className="success">{ success }</p> }
            <Link to="/d3Ya8m3Fp6/case-studies/new" className="new-case-study-link">Create New Case Study</Link>
            <div className="case-study-list">
                { caseStudies.map( caseStudy => (
                    <div key={ caseStudy.id } className="case-study-item">
                        <p><strong>{ caseStudy.title }</strong></p>
                        <Link to={ `/d3Ya8m3Fp6/case-studies/edit/${ caseStudy.id }` }>Edit</Link>
                        <button onClick={ () => handleDeleteCaseStudy( caseStudy.id ) }>Delete</button>
                    </div>
                ) ) }
            </div>
        </div>
    );
}

export default CaseStudyManager;
