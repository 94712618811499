import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ClientLayout from './ClientLayout';
import AdminLayout from './AdminLayout';
import Home from './components/client/Home';
import About from './components/client/About';
import Faq from './components/client/Faq';
import ContactUs from './components/client/ContactUs';
import ServicesPage from './components/client/ServicesPage';
import ServiceDetail from './components/client/ServiceDetail';
import CaseStudies from './components/client/CaseStudies';
import CaseStudy from './components/client/CaseStudy';
import TestimonialsPage from './components/client/TestimonialsPage';
import BlogList from './components/client/BlogList';
import BlogDetail from './components/client/BlogDetail';
import PrivacyPolicy from './components/client/PrivacyPolicy';
import Sitemap from './components/client/Sitemap';
import OptOut from './components/client/OptOut';
import TestimonialManager from './components/d3Ya8m3Fp6/TestimonialManager';
import AddTestimonial from './components/d3Ya8m3Fp6/AddTestimonial';
import Dashboard from './components/d3Ya8m3Fp6/Dashboard';
import AdminLogin from './components/d3Ya8m3Fp6/AdminLogin';
import Settings from './components/d3Ya8m3Fp6/Settings';
import AdminAccounts from './components/d3Ya8m3Fp6/AdminAccounts';
import FaqManager from './components/d3Ya8m3Fp6/FaqManager';
import ServiceManager from './components/d3Ya8m3Fp6/ServiceManager';
import ServiceForm from './components/d3Ya8m3Fp6/ServiceForm';
import CaseStudyManager from './components/d3Ya8m3Fp6/CaseStudyManager';
import CaseStudyForm from './components/d3Ya8m3Fp6/CaseStudyForm';
import BlogManager from './components/d3Ya8m3Fp6/BlogManager';
import RejectedEmails from './components/d3Ya8m3Fp6/RejectedEmails';
import BlogForm from './components/d3Ya8m3Fp6/BlogForm';
import PrivateRoute from './PrivateRoute';
import AdminRoute from './AdminRoute';
import SuperAdminRoute from './SuperAdminRoute';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Client Routes */ }
          <Route path="/" element={ <ClientLayout><Home /></ClientLayout> } />
          <Route path="/about" element={ <ClientLayout><About /></ClientLayout> } />
          <Route path="/faq" element={ <ClientLayout><Faq /></ClientLayout> } />
          <Route path="/services" element={ <ClientLayout><ServicesPage /></ClientLayout> } />
          <Route path="/service/:id" element={ <ClientLayout><ServiceDetail /></ClientLayout> } />
          <Route path="/testimonials" element={ <ClientLayout><TestimonialsPage /></ClientLayout> } />
          <Route path="/contact" element={ <ClientLayout><ContactUs /></ClientLayout> } />
          <Route path="/case-studies" element={ <ClientLayout><CaseStudies /></ClientLayout> } />
          <Route path="/case-study/:id" element={ <ClientLayout><CaseStudy /></ClientLayout> } />
          <Route
            path="/blog"
            element={
              <ClientLayout>
                <BlogList />
              </ClientLayout>
            }
          />
          <Route
            path="/blog/:id"
            element={
              <ClientLayout>
                <BlogDetail />
              </ClientLayout>
            }
          />
          <Route path="/privacy-policy" element={ <ClientLayout><PrivacyPolicy /></ClientLayout> } />
          <Route path="/sitemap" element={ <ClientLayout><Sitemap /></ClientLayout> } />
          <Route path="/opt-out" element={ <ClientLayout><OptOut /></ClientLayout> } />

          {/* Admin Login Route */ }
          <Route path="/d3Ya8m3Fp6" element={ <AdminLogin /> } />

          {/* Admin Dashboard and Management Routes */ }
          <Route path="/d3Ya8m3Fp6/dashboard" element={
            <PrivateRoute><AdminRoute><AdminLayout><Dashboard /></AdminLayout></AdminRoute></PrivateRoute>
          } />
          <Route path="/d3Ya8m3Fp6/settings" element={
            <PrivateRoute><AdminRoute><AdminLayout><Settings /></AdminLayout></AdminRoute></PrivateRoute>
          } />
          <Route path="/d3Ya8m3Fp6/accounts" element={
            <PrivateRoute><SuperAdminRoute><AdminLayout><AdminAccounts /></AdminLayout></SuperAdminRoute></PrivateRoute>
          } />
          <Route path="/d3Ya8m3Fp6/faq-manager" element={
            <PrivateRoute><AdminRoute><AdminLayout><FaqManager /></AdminLayout></AdminRoute></PrivateRoute>
          } />
          <Route path="/d3Ya8m3Fp6/services" element={
            <PrivateRoute><AdminRoute><AdminLayout><ServiceManager /></AdminLayout></AdminRoute></PrivateRoute>
          } />
          <Route path="/d3Ya8m3Fp6/services/new" element={
            <PrivateRoute><AdminRoute><AdminLayout><ServiceForm /></AdminLayout></AdminRoute></PrivateRoute>
          } />
          <Route path="/d3Ya8m3Fp6/services/edit/:id" element={
            <PrivateRoute><AdminRoute><AdminLayout><ServiceForm /></AdminLayout></AdminRoute></PrivateRoute>
          } />

          {/* Testimonial Management Routes */ }
          <Route path="/d3Ya8m3Fp6/testimonials" element={
            <PrivateRoute><AdminRoute><AdminLayout><TestimonialManager /></AdminLayout></AdminRoute></PrivateRoute>
          } />
          <Route path="/d3Ya8m3Fp6/testimonials/new" element={
            <PrivateRoute><AdminRoute><AdminLayout><AddTestimonial /></AdminLayout></AdminRoute></PrivateRoute>
          } />
          <Route path="/d3Ya8m3Fp6/testimonials/edit/:id" element={
            <PrivateRoute><AdminRoute><AdminLayout><AddTestimonial /></AdminLayout></AdminRoute></PrivateRoute>
          } />

          <Route
            path="/d3Ya8m3Fp6/case-studies"
            element={
              <PrivateRoute>
                <AdminRoute>
                  <AdminLayout>
                    <CaseStudyManager />
                  </AdminLayout>
                </AdminRoute>
              </PrivateRoute>
            }
          />
          <Route
            path="/d3Ya8m3Fp6/case-studies/new"
            element={
              <PrivateRoute>
                <AdminRoute>
                  <AdminLayout>
                    <CaseStudyForm />
                  </AdminLayout>
                </AdminRoute>
              </PrivateRoute>
            }
          />
          <Route
            path="/d3Ya8m3Fp6/case-studies/edit/:id"
            element={
              <PrivateRoute>
                <AdminRoute>
                  <AdminLayout>
                    <CaseStudyForm />
                  </AdminLayout>
                </AdminRoute>
              </PrivateRoute>
            }
          />
          <Route path="/d3Ya8m3Fp6/blog" element={ <PrivateRoute><AdminRoute><AdminLayout><BlogManager /></AdminLayout></AdminRoute></PrivateRoute> } />
          <Route path="/d3Ya8m3Fp6/rejected-emails" element={ <PrivateRoute><SuperAdminRoute><AdminLayout><RejectedEmails /></AdminLayout></SuperAdminRoute></PrivateRoute> } />
          <Route path="/d3Ya8m3Fp6/blog/new" element={ <PrivateRoute><AdminRoute><AdminLayout><BlogForm /></AdminLayout></AdminRoute></PrivateRoute> } />
          <Route path="/d3Ya8m3Fp6/blog/edit/:id" element={ <PrivateRoute><AdminRoute><AdminLayout><BlogForm /></AdminLayout></AdminRoute></PrivateRoute> } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
