import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { Helmet } from 'react-helmet';

function ServicesPage() {
    const [ services, setServices ] = useState( [] );

    useEffect( () => {
        const fetchServices = async () => {
            const querySnapshot = await getDocs( collection( db, 'services' ) );
            setServices( querySnapshot.docs.map( doc => ( { id: doc.id, ...doc.data() } ) ) );
        };

        fetchServices();
    }, [] );

    return (
        <div className="services-page">
            <Helmet>
                <title>Services - SafeMobility Solutions Ltd</title>
                <meta name="description" content="At SafeMobility Solutions Ltd, we specialise in delivering state-of-the-art stair climbing solutions tailored to improve accessibility and ensure safety." />
            </Helmet>
            <h1 className="services-heading">Our Services</h1>
            <p>At SafeMobility Solutions Ltd, we specialise in delivering state-of-the-art stair climbing solutions tailored to improve accessibility and ensure safety. Whether you require emergency hire or a long-term solution, we have services that cater precisely to your needs.</p>
            <div className="services-container">
                { services.map( service => (
                    <div key={ service.id } className="service-card">
                        <Link style={ { textDecoration: 'none' } } to={ `/service/${ service.id }` }>
                            <img src={ service.imageUrl } alt={ service.title } style={ { width: '100%' } } />
                            <h3 style={ { textDecoration: 'none' } }>{ service.title }</h3>
                        </Link>
                    </div>
                ) ) }
            </div>
        </div>
    );
}

export default ServicesPage;
