import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

const SuperAdminRoute = ( { children } ) => {
    const [ user, loading ] = useAuthState( auth );
    const [ isSuperAdmin, setIsSuperAdmin ] = useState( false );
    const [ isLoading, setIsLoading ] = useState( true );

    useEffect( () => {
        const checkSuperAdmin = async () => {
            if ( user ) {
                const docRef = doc( db, 'admins', user.uid );
                const docSnap = await getDoc( docRef );

                if ( docSnap.exists() && docSnap.data().type === 'super' ) {
                    setIsSuperAdmin( true );
                }
            }
            setIsLoading( false );
        };

        checkSuperAdmin();
    }, [ user ] );

    if ( loading || isLoading ) {
        return <div>Loading...</div>;
    }

    if ( !user || !isSuperAdmin ) {
        return <Navigate to="/d3Ya8m3Fp6/dashboard" />;
    }

    return children;
};

export default SuperAdminRoute;
