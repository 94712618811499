import React, { useState, useEffect } from 'react';
import { db } from '../../firebaseConfig';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function TestimonialsPage() {
    const [ testimonials, setTestimonials ] = useState( [] );

    useEffect( () => {
        const fetchTestimonials = async () => {
            const q = query(
                collection( db, 'testimonials' ),
                orderBy( 'createdAt', 'desc' ),
            );

            const querySnapshot = await getDocs( q );
            const testimonialsData = querySnapshot.docs.map( doc => ( { id: doc.id, ...doc.data() } ) );
            setTestimonials( testimonialsData );
        };

        fetchTestimonials();
    }, [] );

    return (
        <div className="testimonials-page">
            <Helmet>
                <title>Testimonials - SafeMobility Solutions Ltd</title>
                <meta name="description" content="At SafeMobility Solutions, we are dedicated to enhancing accessibility and safety through our state-of-the-art stair climbing solutions." />
            </Helmet>
            <h1>Customer Testimonials</h1>
            <p className="testimonials-text"><strong>Hear From Our Satisfied Clients</strong></p>
            <p className="testimonials-text">At SafeMobility Solutions, we are dedicated to enhancing accessibility and safety through our state-of-the-art stair climbing solutions. Our clients' experiences speak volumes about our commitment to excellence and customer satisfaction. On this page, you will find genuine testimonials from residential homeowners, housing managers, facilities managers, and event organisers who have benefited from our innovative solutions and outstanding customer service.</p>
            <p className="testimonials-text">Discover how our stair climbers have transformed lives and improved accessibility across various settings. Read our clients' stories and see why we are the trusted choice for emergency hire and bespoke stair accessibility.</p>
            <p className="testimonials-text">Visit our <Link to="/services">Services Page</Link> to learn more about our offerings, or <Link to="/contact">Contact Us</Link> to discuss your requirements.</p>
            <div className="testimonials-grid">
                { testimonials.map( testimonial => (
                    <div key={ testimonial.id } className="testimonial-card">
                        <img src={ testimonial.imageUrl } alt={ testimonial.customerName } className="testimonial-image" />
                        <h3>{ testimonial.customerName }</h3>
                        <h4>{ testimonial.serviceProvided }</h4>
                        <p>"{ testimonial.review }"</p>
                    </div>
                ) ) }
            </div>
        </div>
    );
}

export default TestimonialsPage;
