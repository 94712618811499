import React, { useEffect, useState } from 'react';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function OptOut() {
    const [ message, setMessage ] = useState( '' );
    const location = useLocation();
    const db = getFirestore();

    useEffect( () => {
        const unsubscribeUser = async ( email ) => {
            try {
                // Use email as the document ID to prevent duplicates
                const emailRef = doc( db, 'emails', email );
                await setDoc( emailRef, { email }, { merge: true } );
                setMessage( 'You have successfully unsubscribed. You can now close this tab.' );
            } catch ( error ) {
                console.error( 'Error unsubscribing:', error );
                setMessage( 'There was an error. Please try again later or contact us.' );
            }
        };

        // Extract email from the query parameter
        const query = new URLSearchParams( location.search );
        const email = query.get( 'email' );

        if ( email ) {
            unsubscribeUser( email );
        } else {
            setMessage( 'Invalid request.' );
        }

    }, [ location, db ] );

    return (
        <div className="contact-us-page">
            <Helmet>
                <title>Opt Out - SafeMobility Solutions Ltd</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <h1>Opting out of marketing emails</h1>
            <p>{message}</p>
        </div>
    );
}

export default OptOut;
