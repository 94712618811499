import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebaseConfig';

const PrivateRoute = ( { children } ) => {
    const [ user, loading ] = useAuthState( auth );

    if ( loading ) {
        return <div>Loading...</div>;
    }

    if ( !user ) {
        return <Navigate to="/d3Ya8m3Fp6" />;
    }

    return children;
};

export default PrivateRoute;
