import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

const AdminRoute = ( { children } ) => {
    const [ user, loading ] = useAuthState( auth );
    const [ isAdmin, setIsAdmin ] = useState( false );
    const [ isLoading, setIsLoading ] = useState( true );

    useEffect( () => {
        const checkAdmin = async () => {
            if ( user ) {
                const docRef = doc( db, 'admins', user.uid );
                const docSnap = await getDoc( docRef );

                if ( docSnap.exists() ) {
                    const adminType = docSnap.data().type;
                    if ( adminType === 'super' || adminType === 'admin' ) {
                        setIsAdmin( true );
                    }
                }
            }
            setIsLoading( false );
        };

        checkAdmin();
    }, [ user ] );

    if ( loading || isLoading ) {
        return <div>Loading...</div>;
    }

    if ( !user || !isAdmin ) {
        return <Navigate to="/" />;
    }

    return children;
};

export default AdminRoute;
