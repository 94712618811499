import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { Helmet } from 'react-helmet';

function Sitemap() {
    const [ services, setServices ] = useState( [] );
    const [ caseStudies, setCaseStudies ] = useState( [] );

    useEffect( () => {
        async function fetchData() {
            const servicesSnap = await getDocs( collection( db, "services" ) );
            setServices( servicesSnap.docs.map( doc => ( { id: doc.id, ...doc.data() } ) ) );

            const caseStudiesSnap = await getDocs( collection( db, "caseStudies" ) );
            setCaseStudies( caseStudiesSnap.docs.map( doc => ( { id: doc.id, ...doc.data() } ) ) );
        }

        fetchData();
    }, [] );

    return (
        <div className="sitemap">
            <Helmet>
                <title>Sitemap - SafeMobility Solutions Ltd</title>
            </Helmet>
            <h1>Sitemap</h1>
            <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About Us</Link></li>
                <br />
                <li><Link to="/services">Services</Link></li>
                { services.map( service => (
                    <li key={ service.id }><Link to={ `/service/${ service.id }` }>{ service.title }</Link></li>
                ) ) }
                <br />
                <li><Link to="/case-studies">Case Studies</Link></li>
                { caseStudies.map( study => (
                    <li key={ study.id }><Link to={ `/case-study/${ study.id }` }>{ study.title }</Link></li>
                ) ) }
                <br/>
                <li><Link to="/testimonials">Testimonials</Link></li>
                <li><Link to="/blog">Blog</Link></li>
                <li><Link to="/faq">FAQ</Link></li>
                <li><Link to="/contact">Contact</Link></li>
                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            </ul>
        </div>
    );
}

export default Sitemap;
