import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

function AdminLogin() {
    const [ email, setEmail ] = useState( '' );
    const [ password, setPassword ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const navigate = useNavigate();

    const handleLogin = async ( e ) => {
        e.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword( auth, email, password );
            const user = userCredential.user;

            // Check if the account is disabled
            const docRef = doc( db, 'admins', user.uid );
            const docSnap = await getDoc( docRef );

            if ( docSnap.exists() && docSnap.data().disabled ) {
                throw new Error( 'Account is disabled.' );
            }

            navigate( '/d3Ya8m3Fp6/dashboard' );
        } catch ( error ) {
            setError( error.message );
        }
    };

    return (
        <div className="admin-login">
            <h1>Admin Login</h1>
            { error && <p className="error">{ error }</p> }
            <form onSubmit={ handleLogin }>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        value={ email }
                        onChange={ ( e ) => setEmail( e.target.value ) }
                        required
                    />
                </div>
                <div>
                    <label>Password:</label>
                    <input
                        type="password"
                        value={ password }
                        onChange={ ( e ) => setPassword( e.target.value ) }
                        required
                    />
                </div>
                <button type="submit">Login</button>
            </form>
        </div>
    );
}

export default AdminLogin;
