import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

function Footer() {
    return (
        <footer>
            <div className="footer-column">
                <Link to="/"><img src={ logo } alt="SafeMobility Solutions Ltd" className="footer-logo" /></Link>
                <p>&copy; 2024 SafeMobility Solutions Ltd</p>
            </div>
            <div className="footer-column">
                <h3>Contact Us</h3>
                <p>Phone: <Link to="tel:02030499440" className="phone-link">02030499440</Link></p>
                <p>Email: <Link to="mailto:info@safemobilityservices.com" className="email-link">info@safemobilityservices.com</Link></p>
                <p>Address:<br/>
                <Link to="https://maps.app.goo.gl/LWsdbeGMsB6qARmJA" target="_blank" rel="noopener noreferrer"><b>128 City Road<br/>
                London<br/>
                United Kingdom<br/>
                EC1V 2NX</b></Link></p>
            </div>
            <div className="footer-column">
                <h3>Quick Links</h3>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/services">Services</Link></li>
                    <li><Link to="/case-studies">Case Studies</Link></li>
                    <li><Link to="/blog">Blog</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    <li><Link to="/testimonials">Testimonials</Link></li>
                    <li><Link to="/faq">Frequently Asked Questions</Link></li>
                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                    <li><Link to="/sitemap">Sitemap</Link></li>
                </ul>
            </div>
            <div className="footer-column">
                <h3>Follow Us</h3>
                <div className="socials">
                    <Link to="https://www.facebook.com/safemobilitysolutions/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></Link>
                    <Link to="https://instagram.com/safemobilitysolutions" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></Link>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
