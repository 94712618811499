import React, { useState, useEffect } from 'react';
import { db } from '../../firebaseConfig';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';

function FaqManager() {
    const [ faqs, setFaqs ] = useState( [] );
    const [ question, setQuestion ] = useState( '' );
    const [ answer, setAnswer ] = useState( '' );
    const [ editingId, setEditingId ] = useState( null );
    const [ error, setError ] = useState( '' );
    const [ success, setSuccess ] = useState( '' );

    const fetchFaqs = async () => {
        try {
            const querySnapshot = await getDocs( collection( db, 'faqs' ) );
            const faqList = querySnapshot.docs.map( ( doc ) => ( { id: doc.id, ...doc.data() } ) );
            setFaqs( faqList );
        } catch ( err ) {
            setError( '' );
        }
    };

    useEffect( () => {
        fetchFaqs();
    }, [] );

    const handleAddOrUpdateFaq = async ( e ) => {
        e.preventDefault();
        if ( !question || !answer ) {
            setError( 'Both question and answer are required.' );
            return;
        }

        try {
            if ( editingId ) {
                // Update existing FAQ
                const faqDoc = doc( db, 'faqs', editingId );
                await updateDoc( faqDoc, { question, answer } );
                setSuccess( 'FAQ updated successfully.' );
            } else {
                // Add new FAQ
                await addDoc( collection( db, 'faqs' ), { question, answer } );
                setSuccess( 'FAQ added successfully.' );
            }

            setQuestion( '' );
            setAnswer( '' );
            setEditingId( null );
            fetchFaqs(); // Refresh FAQ list
        } catch ( err ) {
            setError( 'Failed to add/update FAQ.' );
        }
    };

    const handleEditFaq = ( faq ) => {
        setQuestion( faq.question );
        setAnswer( faq.answer );
        setEditingId( faq.id );
    };

    const handleDeleteFaq = async ( id ) => {
        try {
            await deleteDoc( doc( db, 'faqs', id ) );
            setSuccess( 'FAQ deleted successfully.' );
            fetchFaqs(); // Refresh FAQ list
        } catch ( err ) {
            setError( 'Failed to delete FAQ.' );
        }
    };

    return (
        <div className="faq-manager">
            <h1>FAQ Manager</h1>
            { error && <p className="error">{ error }</p> }
            { success && <p className="success">{ success }</p> }

            <form onSubmit={ handleAddOrUpdateFaq }>
                <div>
                    <label>Question:</label>
                    <input
                        type="text"
                        value={ question }
                        onChange={ ( e ) => setQuestion( e.target.value ) }
                        required
                    />
                </div>
                <div>
                    <label>Answer:</label>
                    <textarea
                        value={ answer }
                        onChange={ ( e ) => setAnswer( e.target.value ) }
                        required
                    />
                </div>
                <button type="submit">{ editingId ? 'Update FAQ' : 'Add FAQ' }</button>
            </form>

            <h2>Existing FAQs</h2>
            <div className="faq-list">
                { faqs.map( ( faq ) => (
                    <div key={ faq.id } className="faq-item faq-manager-item">
                        <p><strong>Q:</strong> { faq.question }</p>
                        <p><strong>A:</strong> { faq.answer }</p>
                        <button onClick={ () => handleEditFaq( faq ) }>Edit</button>
                        <button onClick={ () => handleDeleteFaq( faq.id ) }>Delete</button>
                    </div>
                ) ) }
            </div>
        </div>
    );
}

export default FaqManager;
