import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';

function CaseStudy() {
    const { id } = useParams();
    const [ caseStudy, setCaseStudy ] = useState( null );

    useEffect( () => {
        const fetchCaseStudy = async () => {
            const docRef = doc( db, 'caseStudies', id );
            const docSnap = await getDoc( docRef );
            if ( docSnap.exists() ) {
                setCaseStudy( docSnap.data() );
            } else {
                console.log( 'No such caseStudy!' );
            }
        };

        fetchCaseStudy();
    }, [ id ] );

    if ( !caseStudy ) return <div>Loading...</div>;

    const sanitizedDescription = DOMPurify.sanitize( caseStudy.content );

    return (
        <div className="service-detail">
            <Helmet>
                <title>{ caseStudy.title } - Case Studies - SafeMobility Solutions Ltd</title>
            </Helmet>
            <img src={ caseStudy.imageUrl } alt={ caseStudy.title } style={ { width: '100%', height: '400px', objectFit: 'cover' } } />
            <div className="service-detail-special-div">
                <h1>{ caseStudy.title }</h1>
                <div dangerouslySetInnerHTML={ { __html: sanitizedDescription } } />
            </div>
        </div>
    );
}

export default CaseStudy;
