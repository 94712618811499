import React from 'react';
import { Helmet } from 'react-helmet';

function ContactUs() {
    return (
        <div className="contact-us-page">
            <Helmet>
                <title>Contact Us - SafeMobility Solutions Ltd</title>
                <meta name="description" content="If you have any questions or inquiries, please fill out the form." />
            </Helmet>
            <h1>Contact Us</h1>
            <p>If you have any questions or inquiries, please fill out the form below:</p>
            <div className="google-form-container">
                <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLScSSlwLWDmt-c6v8HDiB5QVNFX5mfH_u4aosiFXnrmZnqkOIg/viewform?embedded=true"
                    width="100%"
                    height="800px"
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                    title="Contact Us Form"
                >
                    Loading…
                </iframe>
            </div>
        </div>
    );
}

export default ContactUs;
