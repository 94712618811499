import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import chair_img from '../../assets/images/antano chair.png';
import commitment_img from "../../assets/images/commitment.png";
import wheelchair_img from "../../assets/images/wheelchair.png";

function Home() {
    return (
        <div className="home">
            <Helmet>
                <title>Home - SafeMobility Solutions Ltd</title>
                <meta name="description" content="At SafeMobility Solutions, we specialise in providing advanced stair climber solutions that enhance safety and independence for individuals with mobility challenges." />
            </Helmet>
            <section id="hero">
                <div className="hero-content">
                    <h1>Welcome to SafeMobility Solutions Ltd</h1>
                    <p>At SafeMobility Solutions, we specialise in providing advanced stair climber solutions that enhance safety and independence for individuals with mobility challenges. Our solutions are designed to address a range of accessibility issues, ensuring continuous access and safety across various environments, including emergencies such as lift breakdowns, emergency evacuations, and planned maintenance works.</p>
                    <h2><Link class="hero-button" to="/contact">Get in touch</Link></h2>
                </div>
            </section>

            <section id="services-overview">
                <div className="image-section">
                    <img src={ commitment_img } alt="Our Commitment" style={ { width: '50%' } } />
                </div>
                <div className="text-section">
                    <h2>Our Commitment</h2>
                    <p>Our mission is to empower individuals and organisations by delivering innovative stair climbing solutions that enhance accessibility and mobility. We aim to lead the industry by setting new standards in emergency and planned access and egress services.</p>
                </div>
            </section>

            <section id="services-overview" className="overview-green">
                <div className="text-section">
                    <h2>Why Choose SafeMobility Solutions?</h2>
                    <p><strong>Cutting-Edge Technology</strong>: Our stair climbers feature the latest innovations to ensure smooth and secure operation, including during lift breakdowns or emergency evacuations.</p>
                    <p><strong>Tailored Solutions</strong>: We offer customised options for housing, facilities, event management, and more, providing both hire and purchase services to meet your specific needs.</p>
                    <p><strong>Expert Support</strong>: Our dedicated team offers personalised consultations and ongoing support to ensure the best outcomes for your specific needs, including emergency preparedness and temporary accessibility solutions.</p>
                </div>
                <div className="image-section">
                    <img src={ wheelchair_img } alt="Wheelchair" style={ { width: '70%' } } />
                </div>
            </section>

            <section id="services-overview">
                <div className="image-section">
                    <img src={ chair_img } alt="Antano Chair" style={ { width: '80%' } } />
                </div>
                <div className="text-section">
                    <h2>Explore Our Services</h2>
                    <p>Discover our range of stair climbers and see how they can make a difference in your industry. Whether you need emergency hire services for a lift breakdown planned access during events, or a long-term solution for planned maintenance, we have you covered.</p>
                    <h3><Link className="hp-link green" to="/services">Explore Services</Link></h3>
                    <p>Contact us to schedule a consultation or learn more about our mobility access and egress services and solutions.</p>
                    <h3><Link className="hp-link green" to="/contact">Contact Us</Link></h3>
                </div>
            </section>
        </div>
    );
}

export default Home;
