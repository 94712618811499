import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/dark logo.png';

function About() {
    return (
        <div className="about">
            <Helmet>
                <title>About Us - SafeMobility Solutions Ltd</title>
                <meta name="description" content="We are dedicated to providing comprehensive mobility and safety solutions to ensure the well-being and independence of individuals." />
            </Helmet>
            <section className="about-hero">
                <div className="about-hero-content">
                    <h1>About SafeMobility Solutions Ltd</h1>
                    <p>We are dedicated to providing comprehensive mobility and safety solutions to ensure the well-being and independence of individuals.</p>
                </div>
            </section>
            <section className="about-details">
                <div className="about-left">
                    <img src={ logo } alt="logo" />

                    <h2>Our Story</h2>
                    <p>Founded with the vision of empowering those with mobility challenges, SafeMobility Solutions is committed to providing innovative and flexible stair climbing solutions. We serve a variety of sectors, including housing management, facilities management, and event management. Our offerings are designed to ensure safety and accessibility in diverse environments, providing both hire services for emergencies, planned events and purchase options for long-term use.</p>

                    <h2>Our Mission</h2>
                    <p>To deliver reliable and innovative stair climbing solutions that enhance independence and safety for individuals with mobility impairments. We are dedicated to supporting our clients with flexible options tailored to meet the needs of housing and facilities managers, event organisers, and more. Our services are invaluable in scenarios such as lift breakdowns, emergency evacuations, and planned maintenance.</p>
                    <h3><Link className="hp-link green" to="/testimonials">See what our customers think</Link></h3>
                </div>

                <div className="about-right">
                    <h2>Our Vision</h2>
                    <p>To be the UK's premier provider of mobility solutions, recognised for our commitment to safety, emergency response, innovation, and exceptional customer service. We strive to create an environment where mobility challenges are met with effective solutions, enhancing lives, and ensuring accessibility for all.</p>
                    <h2>Our Values</h2>
                    <ul>
                        <li><strong>Innovation</strong>: We provide innovative stair climbers that ensures safe access and egress access.</li>
                        <li><strong>Flexibility</strong>: We offer customisable solutions to suit a variety of needs, whether through hire or purchase.</li>
                        <li><strong>Customer Focus</strong>: We prioritise understanding and meeting the specific needs of our customers during emergency scenarios, and planned events including key sectors like event, housing, and facilities management.</li>
                        <li><strong>Quality</strong>: We ensure that our services consistently meet the highest standards of excellence.</li>
                    </ul>

                    <h2>Our Team</h2>
                    <p>Our team consists of skilled professionals dedicated to making a difference. With expertise in mobility solutions, we work collaboratively to provide products and services that meet the needs of our clients.</p>

                    <h2>Join Our Journey</h2>
                    <p>Learn more about how our services can help you or your organisation. Discover the benefits of our emergency hire services and purchasing options, and see how we can support your mobility needs, especially during lift breakdowns, planned events, emergency evacuations, and other critical scenarios.</p>
                    <h3><Link className="hp-link green" to="/services">Explore our Services</Link></h3>
                    <h3><Link className="hp-link green" to="/contact">Get in Touch</Link></h3>
                </div>
            </section>
        </div>
    );
}

export default About;
