import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCu1decxUNSn0rpLEDUi_XpFUxjaQEadnw",
    authDomain: "mobility-services-1b358.firebaseapp.com",
    projectId: "mobility-services-1b358",
    storageBucket: "mobility-services-1b358.appspot.com",
    messagingSenderId: "827715165599",
    appId: "1:827715165599:web:7a405410e0d5bcf5a631aa",
    measurementId: "G-9F70P3PNES"
};

const app = initializeApp( firebaseConfig );
const db = getFirestore( app );
const auth = getAuth( app );
const storage = getStorage( app );

export { db, auth, storage, signInWithEmailAndPassword, signOut };