import React, { useState, useEffect } from 'react';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { db } from '../../firebaseConfig';
import { collection, getDocs, setDoc, doc } from 'firebase/firestore';

function AdminAccounts() {
    const [ admins, setAdmins ] = useState( [] );
    const [ email, setEmail ] = useState( '' );
    const [ password, setPassword ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const [ success, setSuccess ] = useState( '' );

    const auth = getAuth();
    const currentUser = auth.currentUser; // Store the current super admin
    const superAdminEmail = currentUser?.email;

    const fetchAdmins = async () => {
        const querySnapshot = await getDocs( collection( db, 'admins' ) );
        const adminsList = querySnapshot.docs.map( ( doc ) => ( { id: doc.id, ...doc.data() } ) );

        // Filter out super admin and disabled accounts
        const filteredAdmins = adminsList.filter( ( admin ) => admin.type !== 'super' && !admin.disabled );

        setAdmins( filteredAdmins );
    };

    useEffect( () => {
        fetchAdmins();
    }, [] );

    const handleCreateAdmin = async ( e ) => {
        e.preventDefault();
        if ( !currentUser ) {
            setError( 'Super admin session is not active.' );
            return;
        }
        try {
            // Save current super admin credentials
            const superAdminPassword = window.prompt( 'Please enter your password to confirm:' );

            // Create the new admin account
            const userCredential = await createUserWithEmailAndPassword( auth, email, password );
            const newUser = userCredential.user;

            // Use uid as the document ID and initialize the account as enabled
            await setDoc( doc( db, 'admins', newUser.uid ), {
                uid: newUser.uid,
                email: email,
                type: 'admin',
                disabled: false // Initially not disabled
            } );

            setSuccess( 'Admin created successfully.' );
            setError( '' );
            setEmail( '' );
            setPassword( '' );

            // Immediately restore the super admin session
            await signInWithEmailAndPassword( auth, superAdminEmail, superAdminPassword );

            // Refresh admin list
            fetchAdmins();
        } catch ( error ) {
            setError( error.message );
            setSuccess( '' );

            // Restore super admin session if something fails
            if ( superAdminEmail ) {
                const superAdminPassword = window.prompt( 'Please re-enter your password to continue:' );
                await signInWithEmailAndPassword( auth, superAdminEmail, superAdminPassword );
            }
        }
    };

    const handleDeleteAdmin = async ( adminId ) => {
        try {
            // Mark admin as disabled in Firestore
            await setDoc( doc( db, 'admins', adminId ), { disabled: true }, { merge: true } );

            // Refresh admin list to remove the "deleted" admin
            fetchAdmins();
            setSuccess( 'Admin deleted successfully.' );
        } catch ( error ) {
            setError( error.message );
        }
    };

    return (
        <div className="admin-accounts">
            <h1>Admin Accounts</h1>
            { error && <p className="error">{ error }</p> }
            { success && <p className="success">{ success }</p> }

            <form onSubmit={ handleCreateAdmin }>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        value={ email }
                        onChange={ ( e ) => setEmail( e.target.value ) }
                        required
                    />
                </div>
                <div>
                    <label>Password:</label>
                    <input
                        type="password"
                        value={ password }
                        onChange={ ( e ) => setPassword( e.target.value ) }
                        required
                    />
                </div>
                <button type="submit">Create Admin</button>
            </form>

            <h2>Existing Admins</h2>
            <div className="table-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        { admins.map( ( admin ) => (
                            <tr key={ admin.id }>
                                <td>{ admin.email }</td>
                                <td>
                                    <button onClick={ () => handleDeleteAdmin( admin.id ) }>Delete</button>
                                </td>
                            </tr>
                        ) ) }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default AdminAccounts;
