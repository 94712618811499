import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

function Navbar() {
    return (
        <header>
            <Link to="/"><img src={ logo } alt="SafeMobility Solutions Ltd" className="logo" /></Link>
            <nav>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/services">Services</Link></li>
                    <li><Link to="/case-studies">Case Studies</Link></li>
                    <li><Link to="/blog">Blog</Link></li>
                    <li><Link to="/faq">FAQ</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </ul>
                <div className="contact-info">
                    <Link to="tel:02030499440" className="phone-link">02030499440</Link>
                    <Link to="mailto:info@safemobilityservices.com" className="email-link">info@safemobilityservices.com</Link>
                </div>
                <div className="socials">
                    <Link to="https://www.facebook.com/safemobilitysolutions/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></Link>
                    <Link to="https://instagram.com/safemobilitysolutions" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></Link>
                </div>
            </nav>
        </header>
    );
}

export default Navbar;
