import React, { useState, useEffect } from 'react';
import { db, storage } from '../../firebaseConfig';  // Ensure storage is imported
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage'; // Import functions for handling storage
import { Link } from 'react-router-dom';

function ServiceManager() {
    const [ services, setServices ] = useState( [] );
    const [ error, setError ] = useState( '' );
    const [ success, setSuccess ] = useState( '' );

    const fetchServices = async () => {
        try {
            const querySnapshot = await getDocs( collection( db, 'services' ) );
            const serviceList = querySnapshot.docs.map( doc => ( { id: doc.id, ...doc.data() } ) );
            setServices( serviceList );
        } catch ( err ) {
            console.error( 'Error fetching services:', err );
            setError( 'Failed to fetch services.' );
        }
    };

    useEffect( () => {
        fetchServices();
    }, [] );

    const handleDeleteService = async ( id, imageUrl ) => {
        try {
            if ( imageUrl ) {
                const imageRef = ref( storage, imageUrl );
                await deleteObject( imageRef ); // Delete the image from Firebase Storage
            }
            await deleteDoc( doc( db, 'services', id ) ); // Delete the Firestore document
            setSuccess( 'Service deleted successfully.' );
            setError( '' );
            fetchServices(); // Refresh the list of services
        } catch ( err ) {
            console.error( 'Error deleting service:', err );
            setError( 'Failed to delete service.' );
        }
    };

    return (
        <div className="service-manager">
            <h1>Service Management</h1>
            { error && <p className="error">{ error }</p> }
            { success && <p className="success">{ success }</p> }

            <Link to="/d3Ya8m3Fp6/services/new" className="new-service-link">Create New Service</Link>

            <h2>Existing Services</h2>
            <div className="service-list">
                { services.map( ( service ) => (
                    <div key={ service.id } className="service-item">
                        <p><strong>{ service.title }</strong></p>
                        <Link to={ `/d3Ya8m3Fp6/services/edit/${ service.id }` }>Edit</Link>
                        <button onClick={ () => handleDeleteService( service.id, service.imageUrl ) }>Delete</button>
                    </div>
                ) ) }
            </div>
        </div>
    );
}

export default ServiceManager;
