import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { Helmet } from 'react-helmet';

function CaseStudies() {
    const [ caseStudies, setCaseStudies ] = useState( [] );

    useEffect( () => {
        const fetchCaseStudies = async () => {
            const querySnapshot = await getDocs( collection( db, 'caseStudies' ) );
            setCaseStudies( querySnapshot.docs.map( doc => ( { id: doc.id, ...doc.data() } ) ) );
        };

        fetchCaseStudies();
    }, [] );

    return (
        <div className="services-page">
            <Helmet>
                <title>Case Studies - SafeMobility Solutions Ltd</title>
                <meta name="description" content="At SafeMobility Solutions Ltd, we pride ourselves on delivering innovative accessibility solutions that transform environments and improve lives." />
            </Helmet>
            <h1 className="services-heading">Case Studies</h1>
            <p className="services-text"><strong>Success Stories</strong></p>
            <p className="services-text">At SafeMobility Solutions Ltd, we pride ourselves on delivering innovative accessibility solutions that transform environments and improve lives. Our case studies showcase how our state-of-the-art stair climbing solutions have been successfully implemented across various sectors, including <strong>residential properties</strong>, <strong>commercial buildings</strong>, and <strong>public facilities</strong>. From <strong>emergency hire services</strong> to long-term hire, discover how we have tailored our offerings to meet the specific needs of our clients.</p>
            <p className="services-text">Explore these <Link to="/testimonials">success stories</Link> to see how we can enhance accessibility and safety for your project. For more information about our services, visit our <Link to="/services">Services Page</Link> or <Link to="/contact">Contact Us</Link> to discuss your specific requirements.</p>
            <div className="services-container">
                { caseStudies.map( caseStudy => (
                    <div key={ caseStudy.id } className="service-card">
                        <Link style={ { textDecoration: 'none' } } to={ `/case-study/${ caseStudy.id }` }>
                            <img src={ caseStudy.imageUrl } alt={ caseStudy.title } style={ { width: '100%' } } />
                            <h3 style={ { textDecoration: 'none' } }>{ caseStudy.title }</h3>
                        </Link>
                    </div>
                ) ) }
            </div>
        </div>
    );
}

export default CaseStudies;
