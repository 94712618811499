import React from 'react';
import { Helmet } from 'react-helmet';

class PrivacyPolicy extends React.Component {
    render() {
        return (
            <div className="faq-page">
                <Helmet>
                    <title>Privacy Policy - SafeMobility Solutions Ltd</title>
                </Helmet>
                <h1><strong>SAFEMOBILITY SOLUTIONS LTD Customer Privacy Notice</strong></h1>
                <p>This privacy notice tells you what to expect us to do with your personal information, as well as your rights under the UK General Data Protection Regulation (GDPR).</p>
                <h2><strong>Contact details</strong></h2>
                <p><strong>Address: </strong>128 City Road, London, EC1V 2NX</p>
                <p><strong>Phone: </strong>02030499440</p>
                <p><strong>Email: </strong><a href="mailto:info@safemobilityservices.com">info@safemobilityservices.com</a></p>
                <h2><strong>What information we collect, use, and why</strong></h2>
                <p>We collect or use the following information to&nbsp;provide goods and services, the types of data we collect include:</p>
                <ul>
                    <li><strong>Identity Information:</strong> Such as your name and contact details</li>
                    <li><strong>Address Information</strong>: For delivery purposes</li>
                    <li><strong>Communication Information</strong>: Including email addresses for updates and notifications</li>
                </ul>
                <p>We collect or use the following information to&nbsp;<strong>comply with legal requirements</strong>:</p>
                <ul>
                    <li><strong>Identity Details:</strong> Such as your name and contact information</li>
                </ul>
                <h2><strong>Lawful bases for processing</strong></h2>
                <h3>To Provide Services:</h3>
                <p>Our lawful bases for collecting or using personal information to&nbsp;<strong>provide services and goods</strong>&nbsp;are:</p>
                <ul>
                    <li><strong>Contractual Necessity</strong>: We process your data to fulfil our contractual obligations and deliver our products and services to you.</li>
                    <li><strong>Legitimate Interests</strong>: This includes processing your data to improve our services, manage our relationship with you.</li>
                </ul>
                <p>Our lawful bases for collecting or using personal information for&nbsp;<strong>legal requirements&nbsp;</strong>are:</p>
                <ul>
                    <li><strong>Contractual Necessity</strong>: We process your data to fulfil our contractual obligations and deliver our products and services to you.</li>
                </ul>
                <h2><strong>Where we get personal information from</strong></h2>
                <p>We collect personal data from the following sources:</p>
                <ul>
                    <li><strong>Direct Interactions</strong>: When you provide information through our website, over the phone, or in person.</li>
                </ul>
                <h2><strong>How long we keep information</strong></h2>
                <p>We will retain your personal information only as long as necessary to fulfil the purposes for which it was collected, to provide services under our contract, or as required by law.</p>
                <h2><strong>Who we share information with</strong></h2>
                <p>Others we share personal information with include:</p>
                <strong><ul>
                    <li>Professional Advisors</li>
                </ul>
                <ul>
                    <li>Professional consultants</li>
                </ul>
                <ul>
                    <li>Employees</li>
                </ul></strong>
                <h2><strong>Your data protection rights</strong></h2>
                <p>Under data protection law, you have rights including:</p>
                <p><strong>Your right of access</strong>&nbsp;- You have the right to ask us for copies of your personal data.</p>
                <p><strong>Your right to rectification</strong>&nbsp;- You have the right to ask us to rectify personal data you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.</p>
                <p><strong>Your right to erasure</strong>&nbsp;- You have the right to ask us to erase your personal data in certain circumstances.</p>
                <p><strong>Your right to restriction of processing</strong>&nbsp;- You have the right to ask us to restrict the processing of your personal data in certain circumstances.</p>
                <p><strong>Your right to object to processing</strong>&nbsp;- You have the right to object to the processing of your personal data in certain circumstances.</p>
                <p><strong>Your right to data portability</strong>&nbsp;- You have the right to ask that we transfer the personal data you gave us to another organisation, or to you, in certain circumstances.</p>
                <p><strong>Your right to withdraw consent</strong>&nbsp;&ndash; When we use consent as our lawful basis you have the right to withdraw your consent.</p>
                <p>You do not usually need to pay a fee to exercise your rights. If you make a request, we have one calendar month to respond to you.</p>
                <p>To make a data protection rights request, or inquire about this notice, please contact us via email at <a href="mailto:info@safemobilityservices.com">info@safemobilityservices.com</a>.</p>
                <h2><strong>How to complain</strong></h2>
                <p>If you have any concerns about our use of your personal data, you can make a complaint to us via email at <a href="mailto:info@safemobilityservices.com">info@safemobilityservices.com</a>.</p>
                <p>If you are not satisfied with our response with how we have used your data after raising a complaint with us, you can lodge a complaint with the Information Commissioner&rsquo;s Office (ICO).</p>
                <p>The ICO&rsquo;s contact via <a href="https://www.ico.org.uk/make-a-complaint">https://www.ico.org.uk/make-a-complaint</a>.</p>
                <h2><strong>Updates to This Privacy Notice</strong></h2>
                <p>We may periodically review and modify this privacy notice to accommodate evolving legal requirements and business practices.</p>
                <strong><p>Last updated</p>
                        <p>5 August 2024</p></strong>
            </div>
        );
    }
}

export default PrivacyPolicy;
