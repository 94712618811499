import React, { useState, useEffect } from 'react';
import { db, storage } from '../../firebaseConfig';
import { collection, doc, setDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function BlogForm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [ title, setTitle ] = useState( '' );
    const [ content, setContent ] = useState( '' );
    const [ image, setImage ] = useState( null );
    const [ imageUrl, setImageUrl ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const [ success, setSuccess ] = useState( '' );
    const [ loading, setLoading ] = useState( false );

    useEffect( () => {
        if ( id ) {
            const fetchBlogPost = async () => {
                const docRef = doc( db, 'blogPosts', id );
                const docSnap = await getDoc( docRef );
                if ( docSnap.exists() ) {
                    const data = docSnap.data();
                    setTitle( data.title );
                    setContent( data.content );
                    setImageUrl( data.imageUrl );
                } else {
                    console.log( 'No such document!' );
                }
            };
            fetchBlogPost();
        }
    }, [ id ] );

    const handleImageUpload = async () => {
        if ( !image ) return imageUrl; // Return existing imageUrl if no new image is uploaded
        const storageRef = ref( storage, `blogPosts/${ Date.now() }_${ image.name }` );
        await uploadBytes( storageRef, image );
        return await getDownloadURL( storageRef );
    };

    const handleSubmit = async ( e ) => {
        e.preventDefault();
        setLoading( true );
        try {
            const newImageUrl = await handleImageUpload();
            const blogPostData = {
                title,
                content,
                imageUrl: newImageUrl,
                updatedAt: new Date(),
            };

            if ( !id ) {
                // Add createdAt only for new posts
                blogPostData.createdAt = new Date();
            }

            const docRef = id ? doc( db, 'blogPosts', id ) : doc( collection( db, 'blogPosts' ) );
            await setDoc( docRef, blogPostData, { merge: true } ); // Use merge to update existing posts without overwriting

            setSuccess( 'Blog post saved successfully.' );
            navigate( '/d3Ya8m3Fp6/blog' );
        } catch ( error ) {
            console.error( 'Error saving blog post:', error );
            setError( 'Failed to save blog post.' );
        } finally {
            setLoading( false );
        }
    };

    return (
        <div className="case-study-form">
            <h1>{ id ? 'Edit Blog Post' : 'Create New Blog Post' }</h1>
            { error && <p className="error">{ error }</p> }
            { success && <p className="success">{ success }</p> }
            <form onSubmit={ handleSubmit }>
                <div>
                    <label>Title:</label>
                    <input type="text" value={ title } onChange={ ( e ) => setTitle( e.target.value ) } required />
                </div>
                <div>
                    <label>Content:</label>
                    <ReactQuill
                        theme="snow"
                        value={ content }
                        onChange={ setContent }
                        modules={ {
                            toolbar: [
                                [ { header: [ 2, 3, false ] } ],
                                [ 'bold', 'italic', 'underline', 'strike' ],
                                [ { list: 'ordered' }, { list: 'bullet' } ],
                                [ 'link', 'image', 'video' ],
                                [ 'clean' ]
                            ]
                        } }
                    />
                </div>
                <div>
                    <label>Image:</label>
                    <input type="file" accept="image/*" onChange={ ( e ) => setImage( e.target.files[ 0 ] ) } />
                </div>
                { imageUrl && <img src={ imageUrl } alt="Blog" style={ { width: '100px', marginTop: '10px' } } /> }
                <button type="submit" disabled={ loading }>{ loading ? 'Saving...' : ( id ? 'Update Blog Post' : 'Add Blog Post' ) }</button>
            </form>
        </div>
    );
}

export default BlogForm;
