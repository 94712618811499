import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { auth, db } from '../../firebaseConfig'; // Import Firebase instances
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, getDoc } from 'firebase/firestore';

function AdminNavbar() {
    const [ user ] = useAuthState( auth );
    const [ isSuperAdmin, setIsSuperAdmin ] = useState( false );

    useEffect( () => {
        const checkAdminType = async () => {
            if ( user ) {
                const docRef = doc( db, 'admins', user.uid );
                const docSnap = await getDoc( docRef );

                if ( docSnap.exists() && docSnap.data().type === 'super' ) {
                    setIsSuperAdmin( true );
                }
            }
        };

        checkAdminType();
    }, [ user ] );

    return (
        <nav className="admin-navbar">
            <ul>
                { isSuperAdmin && <li><Link to="/d3Ya8m3Fp6/accounts">Admin Accounts</Link></li> }
                { isSuperAdmin && <li><Link to="/d3Ya8m3Fp6/rejected-emails">Opted out</Link></li> }
                <li><Link to="/d3Ya8m3Fp6/faq-manager">FAQ</Link></li>
                <li><Link to="/d3Ya8m3Fp6/case-studies">Case Studies</Link></li>
                <li><Link to="/d3Ya8m3Fp6/testimonials">Testimonials</Link></li>
                <li><Link to="/d3Ya8m3Fp6/services">Services</Link></li>
                <li><Link to="/d3Ya8m3Fp6/blog">Blog</Link></li>
                <li><Link to="/d3Ya8m3Fp6/settings">Settings</Link></li>
            </ul>
        </nav>
    );
}

export default AdminNavbar;
