import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';

function BlogDetail() {
    const { id } = useParams();
    const [ blogPost, setBlogPost ] = useState( null );

    useEffect( () => {
        const fetchBlogPost = async () => {
            const docRef = doc( db, 'blogPosts', id );
            const docSnap = await getDoc( docRef );
            if ( docSnap.exists() ) {
                setBlogPost( docSnap.data() );
            } else {
                console.log( 'No such blog post!' );
            }
        };

        fetchBlogPost();
    }, [ id ] );

    if ( !blogPost ) return <div>Loading...</div>;

    const sanitizedContent = DOMPurify.sanitize( blogPost.content );

    return (
        <div className="blog-detail">
            <Helmet>
                <title>{ blogPost.title } - Blog - SafeMobility Solutions Ltd</title>
            </Helmet>
            <img src={ blogPost.imageUrl } alt={ blogPost.title } className="blog-banner" />
            <div className="blog-content">
                <h1>{ blogPost.title }</h1>
                <p className="blog-date">Posted on { new Date( blogPost.createdAt.seconds * 1000 ).toLocaleDateString( 'en-GB', { month: 'long', day: 'numeric', year: 'numeric' } ) }</p>
                <p className="blog-date">Last updated on { new Date( blogPost.updatedAt.seconds * 1000 ).toLocaleDateString( 'en-GB', { month: 'long', day: 'numeric', year: 'numeric' } ) }</p>
                <div dangerouslySetInnerHTML={ { __html: sanitizedContent } } />
            </div>
        </div>
    );
}

export default BlogDetail;
