import React, { useState, useEffect } from 'react';
import { db, storage } from '../../firebaseConfig';
import { collection, getDoc, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';
import { Link } from 'react-router-dom';

function BlogManager() {
    const [ blogPosts, setBlogPosts ] = useState( [] );
    const [ error, setError ] = useState( '' );
    const [ success, setSuccess ] = useState( '' );

    useEffect( () => {
        const fetchBlogPosts = async () => {
            try {
                const querySnapshot = await getDocs( collection( db, 'blogPosts' ) );
                setBlogPosts( querySnapshot.docs.map( doc => ( { id: doc.id, ...doc.data() } ) ) );
            } catch ( error ) {
                console.error( 'Error fetching blog posts:', error );
                setError( 'Failed to load blog posts.' );
            }
        };

        fetchBlogPosts();
    }, [] );

    const handleDeleteBlogPost = async ( id ) => {
        try {
            // First get the blog post to access the imageUrl
            const blogRef = doc( db, 'blogPosts', id );
            const blogSnap = await getDoc( blogRef );
            if ( blogSnap.exists() ) {
                // Delete the image from storage
                const imageUrl = blogSnap.data().imageUrl;
                if ( imageUrl ) {
                    const imageRef = ref( storage, imageUrl );
                    await deleteObject( imageRef );
                }

                // Then delete the document
                await deleteDoc( blogRef );
                setBlogPosts( blogPosts.filter( post => post.id !== id ) );
                setSuccess( 'Blog post deleted successfully.' );
            } else {
                setError( 'Blog post not found.' );
            }
        } catch ( error ) {
            console.error( 'Error deleting blog post:', error );
            setError( 'Failed to delete blog post.' );
        }
    };

    return (
        <div className="blog-manager">
            <h1>Manage Blog Posts</h1>
            { error && <p className="error">{ error }</p> }
            { success && <p className="success">{ success }</p> }
            <Link to="/d3Ya8m3Fp6/blog/new" className="new-blog-post-link">Create New Blog Post</Link>
            <div className="blog-post-list">
                { blogPosts.map( post => (
                    <div key={ post.id } className="blog-post-item">
                        <p><strong>{ post.title }</strong></p>
                        <Link to={ `/d3Ya8m3Fp6/blog/edit/${ post.id }` }>Edit</Link>
                        <button onClick={ () => handleDeleteBlogPost( post.id ) }>Delete</button>
                    </div>
                ) ) }
            </div>
        </div>
    );
}

export default BlogManager;
