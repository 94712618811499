import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';

function ServiceDetail() {
    const { id } = useParams();
    const [ service, setService ] = useState( null );

    useEffect( () => {
        const fetchService = async () => {
            const docRef = doc( db, 'services', id );
            const docSnap = await getDoc( docRef );
            if ( docSnap.exists() ) {
                setService( docSnap.data() );
            } else {
                console.log( 'No such service!' );
            }
        };

        fetchService();
    }, [ id ] );

    if ( !service ) return <div>Loading...</div>;

    const sanitizedDescription = DOMPurify.sanitize( service.description );

    return (
        <div className="service-detail">
            <Helmet>
                <title>{ service.title } - Services - SafeMobility Solutions Ltd</title>
            </Helmet>
            <img src={ service.imageUrl } alt={ service.title } style={ { width: '100%', height: '400px', objectFit: 'cover' } } />
            <div className="service-detail-special-div">
                <h1>{ service.title }</h1>
                <div dangerouslySetInnerHTML={ { __html: sanitizedDescription } } />
            </div>
        </div>
    );
}

export default ServiceDetail;
